<template>
  <div>
    <v-form
      @submit.stop.prevent="get_notifications_by_page()"
      ref="form"
      lazy-validation
    >
      <v-text-field
        :label="$t('search')"
        append-icon="mdi-magnify"
        @click:append="get_notifications_by_page"
        v-model="searchFilter"
        filled
        dense
        hide-details
        class="mx-auto"
        style="width: 100%; max-width: 500px; border-radius: 0px !important"
      >
      </v-text-field>
    </v-form>
    <v-row justify="center" class="mt-4" v-if="loading">
      <v-progress-circular
        indeterminate
        size="20"
        color="primary"
        data-test="loading"
      ></v-progress-circular>
    </v-row>
    <div v-else-if="notifications.length == 0">
      <p class="not-found text-center my-10 dark-color">
        {{ $t("request_not_found") }}
      </p>
    </div>
    <div v-else>
      <v-simple-table style="background: var(--white)" v-if="!gs.isMobile()">
        <thead>
          <tr>
            <th>{{ $t("requester") }}</th>
            <th>{{ $tc("company", 1) }}</th>
            <th>{{ $t("value") }}</th>
            <th>{{ $t("date") }}</th>
            <th class="text-center">{{ $t("actions") }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(n, index) in notifications" :key="index">
            <td class="dark-color">{{ gs.show_full_name(n.Sender) }}</td>
            <td class="dark-color">{{ n.CompanyInterest.Company.Name }}</td>
            <td
              class="dark-color"
              :data-test="`Notification:ReceivedTemplate:Value${n.CompanyInterest.Value}`"
            >
              {{
                formatToCurrency(
                  n.CompanyInterest.Currency,
                  n.CompanyInterest.Value
                )
              }}
            </td>
            <td class="dark-color">{{ gs.convert_date(n.Timestamp, true) }}</td>
            <td class="text-center" v-if="Status == 0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <font-awesome-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="manage_click(n, signatureNotificationsEnum.Confirm)"
                    icon="fa-solid fa-check"
                    class="received-table-action-icon received-table-check-icon"
                    :data-test="`Notification:ReceivedTemplate:Check:${n.CompanyInterest.Value}`"
                  />
                </template>
                <span>{{ $t("confirm") }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <font-awesome-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="manage_click(n, signatureNotificationsEnum.Edit)"
                    icon="fa-solid fa-pencil"
                    class="received-table-action-icon received-table-edit-icon"
                    :data-test="`Notification:ReceivedTemplate:Edit:${n.CompanyInterest.Value}`"
                  />
                </template>
                <span>{{ $t("edit") }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <font-awesome-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="manage_click(n, signatureNotificationsEnum.Decline)"
                    icon="fa-solid fa-xmark"
                    class="received-table-action-icon received-table-refuse-icon"
                    :data-test="`Notification:ReceivedTemplate:Decline:${n.CompanyInterest.Value}`"
                  />
                </template>
                <span>{{ $t("decline") }}</span>
              </v-tooltip>
            </td>
            <td class="text-center" v-else>
              <v-btn
                class="dxa-btn-secondary"
                style="margin-right: 12px !important"
                @click="manage_click(n, null)"
              >
                <v-icon class="mr-1">mdi-text-box-outline</v-icon>
                <span>{{ $t("detail") }}</span>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div v-else>
        <p class="my-2" style="color: #9c9c9c">
          {{ $t("approvals_mobile_desc") }}
        </p>
        <div
          v-for="(n, index) in notifications"
          :key="index"
          class="mobile-request"
        >
          <a style="display: flex" @click="manage_click(n, null)">
            <v-col
              cols="6"
              style="display: flex; align-items: center; justify-content: start"
            >
              <div>
                <p
                  style="
                    color: var(--dark);
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0.15px;
                    margin-bottom: 2px;
                  "
                >
                  {{ gs.convert_date(n.Timestamp, true) }}
                </p>
                <p
                  style="
                    color: var(--dark);
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.15px;
                    margin-bottom: 2px;
                  "
                >
                  {{ n.CompanyInterest.Company.Name }}
                </p>
                <p
                  style="
                    color: var(--dark);
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0.15px;
                  "
                >
                  {{ gs.show_full_name(n.Sender) }}
                </p>
              </div>
            </v-col>
            <v-col
              cols="6"
              style="display: flex; justify-content: end; align-items: center"
            >
              <p
                style="
                  font-style: normal;
                  font-weight: bold;
                  font-size: 16px;
                  line-height: 20px;
                  text-align: center;
                  letter-spacing: 0.15px;
                "
              >
                {{
                  formatToCurrency(
                    n.CompanyInterest.Currency,
                    n.CompanyInterest.Value
                  )
                }}
              </p>
            </v-col>
          </a>
        </div>
      </div>
      <v-pagination
        class="custom-pagination"
        color="primary"
        @input="changePage"
        :length="total_pages"
        v-model="page"
      >
      </v-pagination>
    </div>
    <v-dialog v-model="manageModal" v-if="manageModal && !gs.isMobile()">
      <ManageSignatureNotificationModal
        :Notification="notification"
        :Option="manageOption"
        @close="reset_modal"
        @remove="remove_notification"
      />
    </v-dialog>
    <v-bottom-sheet v-model="manageModal" v-if="manageModal && gs.isMobile()">
      <ManageSignatureNotificationModal
        :Notification="notification"
        :Option="manageOption"
        @close="reset_modal"
        @remove="remove_notification"
      />
    </v-bottom-sheet>
  </div>
</template>
<style scoped>
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.custom-pagination >>> .v-pagination__navigation,
.custom-pagination >>> .v-pagination__item:not(.v-pagination__item--active) {
  background-color: var(--white) !important;
  color: var(--dark);
}

.custom-pagination >>> .v-pagination__item--active {
  color: var(--white);
}

.mobile-request {
  height: 72px;
  width: 100%;
  border: 0.5px solid #9c9c9c;
  box-sizing: border-box;
  border-radius: 12px;
  margin-bottom: 6px;
}
.not-found {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
}

.received-table-action-icon {
  width: 20px;
  height: 20px;
  padding: 20px;
  cursor: pointer;
}

.received-table-check-icon {
  color: var(--primary);
}

.received-table-refuse-icon {
  color: #ff0a36;
}
</style>
<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import ManageSignatureNotificationModal from "../../form/ManageSignatureNotificationModal";
import { formatCurrency } from "@/shared/helpers/currencyHelper";
import { signatureNotificationsEnum } from "../../enums/SignatureNotificationsEnums";

export default {
  name: "ReceivedSignatureNotificationsTemplate",

  components: { ManageSignatureNotificationModal },

  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    loading: false,
    page: 1,
    total_pages: 1,
    valid: true,
    lazy: false,
    searchFilter: null,
    manageModal: false,
    manageOption: 0,
    notification: null,
    notifications: [],
    formatToCurrency: formatCurrency,
    signatureNotificationsEnum,
  }),
  async created() {
    this.get_notifications_by_page();
  },
  props: {
    Status: Number,
  },
  watch: {
    Status() {
      this.get_notifications_by_page();
    },
  },
  computed: {},
  methods: {
    remove_notification() {
      var index = this.notifications.indexOf(this.notification);
      this.notifications.splice(index, 1);
      this.reset_modal();
    },
    reset_modal() {
      this.notification = null;
      this.manageModal = false;
    },
    manage_click(notification, option) {
      this.notification = notification;
      this.manageOption = option;
      this.manageModal = true;
    },
    redirect(n) {
      this.$router.push(
        `/companies/briefing/${n.CompanyInterest.CompanyId}?notification=${n.Id}`
      );
    },
    async get_notifications_by_page() {
      this.loading = true;
      var page = this.page;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page);
      }
      var params = {
        Page: page,
        Status: this.Status,
        TextFilter: this.searchFilter,
      };
      await this.apiService
        .postRequest("signaturenotification/list-received/filter", params)
        .then((resp) => {
          var json = JSON.parse(resp.message);
          this.page = json.Pager.CurrentPage;
          this.total_pages = json.Pager.TotalPages;
          this.notifications = json.Notifications;
        });
      this.loading = false;
    },
    changePage() {
      this.$router.replace({
        query: { page: this.page, tab: this.Status + 1 },
      });
      this.get_notifications_by_page();
    },
  },
};
</script>
