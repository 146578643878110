<template>
  <v-card class="dxa_modal" color="white">
    <v-card-text class="pa-4 pt-0">
      <v-row>
        <v-col cols="12" md="4">
          <p class="field-header">{{ $t("request_date") }}</p>
          <p class="field-value">
            {{ gs.convert_date(notification.Timestamp, false) }}
          </p>
        </v-col>
        <v-col cols="12" md="4">
          <p class="field-header">{{ $tc("company", 1) }}</p>
          <p class="field-value">
            {{ notification.CompanyInterest.Company.Name }}
          </p>
        </v-col>
        <v-col v-if="!gs.isMobile()" cols="4"> </v-col>
        <v-col cols="12" md="4">
          <p class="field-header">{{ $t("requester") }}</p>
          <p class="field-value">
            {{ gs.show_full_name(notification.Sender) }}
          </p></v-col
        >
        <v-col v-if="!gs.isMobile()" cols="4"> </v-col>
        <v-col
          cols="12"
          md="4"
          :class="gs.isMobile() ? 'text-left' : 'text-center'"
        >
          <p class="field-header">{{ $t("value") }}</p>
          <p class="request-value">
            {{
              formatToCurrency(
                notification.CompanyInterest.Currency,
                notification.CompanyInterest.Value
              )
            }}
          </p>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row v-if="gs.isMobile() && notification.Status == 0">
        <div class="notification-actions-buttons-container">
          <v-btn
            :class="
              option == signatureNotificationsEnum.Confirm
                ? 'dxa-btn-primary'
                : 'dxa-btn-secondary'
            "
            style="width: 100%"
            @click="option = signatureNotificationsEnum.Confirm"
          >
            <v-icon class="mr-1">mdi-check</v-icon>
            {{ $t("confirm") }}
          </v-btn>
          <v-btn
            :class="
              option == signatureNotificationsEnum.Edit
                ? 'dxa-btn-primary'
                : 'dxa-btn-secondary'
            "
            style="width: 100%"
            @click="option = signatureNotificationsEnum.Edit"
          >
            <v-icon class="mr-1">mdi-pencil</v-icon>
            {{ $t("edit") }}
          </v-btn>
          <v-btn
            :class="
              option == signatureNotificationsEnum.Decline
                ? 'dxa-btn-primary-danger'
                : 'dxa-btn-secondary-danger'
            "
            style="width: 100%"
            @click="option = signatureNotificationsEnum.Decline"
          >
            <v-icon class="mr-1">mdi-close</v-icon>
            {{ $t("decline") }}
          </v-btn>
        </div>
        <v-col cols="12" v-if="option == null">
          <p class="choose-option-msg">{{ $t("choose_options_above") }}</p>
        </v-col>
      </v-row>
      <v-form @submit.stop.prevent="submit()" ref="form" class="mt-3">
        <div v-if="notification.Status == 2">
          <p class="consideration-title mb-1">{{ $t("justification") }}</p>
          <div
            style="background: #e9e9e9; border-radius: 5px"
            class="justification-declined-allocation-container"
          >
            <p class="consideration-text pa-3">
              {{ notification.ResponseText }}
            </p>
          </div>
        </div>
        <div
          v-else-if="option == signatureNotificationsEnum.Edit"
          class="notification-input-money"
        >
          <DXATextField
            :IsCurrency="true"
            :Currency="notification.CompanyInterest.Currency"
            :Field="$t('type_new_value')"
            :Text="notification.CompanyInterest.Value"
            :ErrorText="errorValue"
            @update="
              (value) => {
                notification.CompanyInterest.Value = value;
              }
            "
            @text_changed="validate_value"
            data-test="Notification:ManageModal:pNewValue"
          />
        </div>
        <div
          v-else-if="option == signatureNotificationsEnum.Decline"
          class="notification-input-money"
        >
          <DXATextField
            :Field="$t('decline_reason')"
            :Text="notification.ResponseText"
            :ErrorText="errorResponse"
            @update="
              (value) => {
                notification.ResponseText = value;
              }
            "
            @text_changed="validate_response"
            data-test="Notification:ManageModal:txtDeclineReason"
          />
        </div>

        <v-card-actions
          style="display: flex"
          class="mt-4 actions-button-container"
          :class="{ 'actions-buttons-start': option !== null }"
        >
          <v-btn
            v-if="notification.Status != 0 || option == null"
            class="dxa-btn-secondary btn-close-modal-notification"
            min-width="100"
            width="50%"
            @click="$emit('close')"
          >
            {{ $t("close") }}
          </v-btn>
          <div style="display: flex" v-else>
            <v-btn
              class="dxa-btn-secondary mx-3 notification-modal-button-width"
              min-width="100"
              @click="$emit('close')"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              :class="
                option == signatureNotificationsEnum.Decline
                  ? 'dxa-btn-primary-danger'
                  : 'dxa-btn-primary'
              "
              class="mx-3 notification-modal-button-width white-color"
              min-width="100"
              :loading="loading"
              type="submit"
              :data-test="`Notification:ManageModal:Btn${submit_btn_label}`"
              >{{ $t(submit_btn_label) }}</v-btn
            >
          </div>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<style scoped>
.consideration-title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #9c9c9c;
}

.consideration-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #9c9c9c;
}

.choose-option-msg {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.15px;
  word-break: break-all;
}

.field-header {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: #9c9c9c;
  margin-bottom: 0px !important;
}

.field-value {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 0px !important;
}

.request-value {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: var(--primary);
  margin-bottom: 0px !important;
}

.notification-actions-buttons-container > button {
  max-width: max-content;
  flex: 1;
}

.notification-actions-buttons-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 32px;
}

.actions-button-container {
  max-width: 320px;
  justify-content: center;
}

.notification-modal-button-width {
  width: 45%;
}

/* mobile - s */
@media (max-width: 370px) {
  .notification-actions-buttons-container {
    gap: 10px 0px;
    max-width: 320px;
    justify-content: center;
  }

  .notification-actions-buttons-container > button {
    max-width: max-content;
    flex: none;
  }

  .choose-option-msg {
    margin-right: 50px !important;
    font-size: 12px;
  }

  .btn-close-modal-notification {
    max-width: 130px;
    margin-right: 32px !important;
  }

  .actions-buttons-start {
    justify-content: flex-start !important;
  }

  .notification-modal-button-width {
    width: 100px;
  }

  .notification-input-money {
    width: 260px;
    margin-top: 20px;
  }

  .justification-declined-allocation-container {
    width: 260px;
  }
}
</style>
<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import DXATextField from "@/components/dxa/TextField/DxaTextField";
import { formatCurrency } from "@/shared/helpers/currencyHelper";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";
import { signatureNotificationsEnum } from "../enums/SignatureNotificationsEnums";

export default {
  name: "ManageSignatureNotificationModal",
  components: { DXATextField },
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    notification: null,
    minAllocationValue: null,
    errorValue: null,
    errorResponse: null,
    option: null,
    formatToCurrency: formatCurrency,
    signatureNotificationsEnum,
  }),
  props: {
    Notification: Object,
    Option: Number,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
    requiredNumber() {
      return (value) => value != null || this.$t("required");
    },
    submit_btn_label() {
      if (this.option == this.signatureNotificationsEnum.Confirm) {
        return "confirm";
      } else if (this.option == this.signatureNotificationsEnum.Edit) {
        return "edit_send";
      } else {
        return "decline";
      }
    },
  },
  async created() {
    this.option = this.Option;
    this.notification = JSON.parse(JSON.stringify(this.Notification));
    await this.get_min_allocation_value();
  },
  methods: {
    async get_min_allocation_value() {
      await this.apiService
        .getRequest(
          `company/min-allocation-value-new/${this.notification.CompanyInterest.CompanyId}/${this.notification.CompanyInterest.Currency}`
        )
        .then((result) => {
          this.minAllocationValue = result.content
            ? result.content.minValue
            : 0;
        })
        .catch((error) => {});
      var partnerId = JSON.parse(localStorage.getItem("user")).partnerId;
    },
    validate_value() {
      this.errorValue = null;
      if (
        this.notification.CompanyInterest.Value <
          this.minAllocationValue.minValue &&
        this.notification.CompanyInterest.Currency === CurrencyEnum.BRL
      ) {
        this.errorValue =
          this.$t("min_allocation_value") +
          ": " +
          this.gs.format_to_currency(this.minAllocationValue.minValue);
      }
    },
    validate_response() {
      this.errorResponse = null;
      if (
        this.notification.ResponseText == null ||
        this.notification.ResponseText.length == 0
      ) {
        this.errorResponse = this.$t("required");
      }
    },
    async submit() {
      if (this.option == this.signatureNotificationsEnum.Edit) {
        this.validate_value();
      } else if (this.option == this.signatureNotificationsEnum.Decline) {
        this.validate_response();
      }
      if (this.loading || this.errorValue || this.errorResponse) {
        return;
      }

      this.loading = true;
      var params = {
        ResponseText: this.notification.ResponseText,
        Value: parseFloat(this.notification.CompanyInterest.Value),
        Id: this.notification.Id,
      };
      if (this.option == this.signatureNotificationsEnum.Confirm) {
        this.$router.push(
          `/companies/briefing/${this.notification.CompanyInterest.CompanyId}?notification=${this.notification.Id}&responseText=${this.notification.ResponseText}`
        );
      } else if (this.option == this.signatureNotificationsEnum.Edit) {
        await this.apiService.postRequest(
          "signaturenotification/change-value",
          params
        );
        this.$router.push(
          `/companies/briefing/${this.notification.CompanyInterest.CompanyId}?notification=${this.notification.Id}`
        );
      } else {
        await this.apiService.postRequest(
          "companyinterest/decline-suggested-allocation",
          params
        );
        this.$emit("remove");
      }
      return;
    },
  },
};
</script>
