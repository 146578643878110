<template>
  <v-container fluid tag="section">
    <p
      style="
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 0px;
      "
      class="dark-color"
    >
      {{ $t("suggested_allocation") }}
    </p>
    <span
      style="
        font-weight: 300;
        font-size: 15px;
        line-height: 35px;
        color: #9c9c9c;
      "
      >{{ $t("suggested_allocation_desc") }}</span
    >
    <v-tabs
      show-arrows
      grow
      centered
      background-color="transparent"
      v-model="tab"
      class="dxa-tabs mt-4"
    >
      <v-tab
        v-for="(item, index) in tab_options"
        :key="index"
        @click="index_changed(item.index)"
        :data-test="`Notification:ReceivedNotifications:tab${item.index}`"
      >
        {{ item.title }}
      </v-tab>
    </v-tabs>
    <div id="interest-table" class="dxa-card mt-3">
      <ReceivedTemplate :Status="tab" />
    </div>
  </v-container>
</template>

<style scoped>
#interest-table {
  max-width: unset !important;
  width: 100% !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import ReceivedTemplate from "./templates/ReceivedTemplate.vue";

export default {
  name: "ReceivedNotifications",

  components: { ReceivedTemplate },

  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    tab: 0,
  }),
  async created() {},
  computed: {
    tab_options() {
      return [
        {
          title: this.$tc("suggested_allocation_waiting_approval", 2),
          index: 0,
        },
        { title: this.$tc("suggested_allocation_accepted", 2), index: 1 },
        { title: this.$tc("suggested_allocation_refused", 2), index: 2 },
      ];
    },
  },
  methods: {
    index_changed(index) {
      this.$router.replace({ query: { tab: index + 1 } });
    },
  },
};
</script>
